import { pieArcLabelClasses, PieChart } from '@mui/x-charts/PieChart';
import clsx from 'clsx';
import React, { FC } from 'react';

import s from './index.module.scss';

import { useFetch } from '#root/hooks/use-fetch';
import { useEditableParty } from '#root/hooks/use-party';
import { useTranslation } from '#root/hooks/use-translation';
import { IEditableParty } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';

interface IResult {
  attending: number;
  notAttending: number;
  noResponse: number;
  rsvpChoiceFields?: {
    [fieldId: string]: {
      count: number;
      persons: number;
    };
  };
}

const RsvpStats: FC<{ stat: IResult | null; party: IEditableParty }> = ({ stat, party }) => {
  const t = useTranslation();
  if (!stat) {
    return <></>;
  }

  const data: Array<{ value: number; label: string; color: string }> = [];

  if (stat.attending > 0) {
    data.push({
      value: stat.attending,
      label: `${t(Keys.admin.rsvp.stat.attending)} (${stat.attending})`,
      color: '#00bfa5',
    });
  }
  if (stat.notAttending > 0) {
    data.push({
      value: stat.notAttending,
      label: `${t(Keys.admin.rsvp.stat.not_attending)} (${stat.notAttending})`,
      color: '#dd686e',
    });
  }
  if (stat.noResponse > 0) {
    data.push({
      value: stat.noResponse,
      label: `${t(Keys.admin.rsvp.stat.missing)} (${stat.noResponse})`,
      color: '#bebebe',
    });
  }

  const rsvpChoiceFields = party.rsvpFields.filter(
    field => !field.hidden && field.type === 'choice'
  );

  return (
    <div className={s.root}>
      <div className={s.pie}>
        <PieChart
          series={[
            {
              data,
              innerRadius: 30,
              outerRadius: 80,
              paddingAngle: 6,
              cornerRadius: 10,
              arcLabel: 'value',
              arcLabelMinAngle: 20,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: '#222',
              // fontWeight: 'bold',
            },
          }}
          width={500}
          height={200}
        />
      </div>
      {rsvpChoiceFields.length ? (
        <div>
          <h4 className={clsx(css.fontSans, css.fontColorGrey3, css.fontNormal, css.fontUppercase)}>
            {t(Keys.admin.rsvp.stat.rsvp_fields)}
          </h4>
          {rsvpChoiceFields.map(rsvpField => (
            <div>
              {stat.rsvpChoiceFields?.[rsvpField.id].count !==
              stat.rsvpChoiceFields?.[rsvpField.id].persons ? (
                <>
                  <b>{rsvpField.name}:</b> {stat.rsvpChoiceFields?.[rsvpField.id].count} (
                  {stat.rsvpChoiceFields?.[rsvpField.id].persons} {t(Keys.admin.rsvp.stat.persons)})
                </>
              ) : (
                <>
                  <b>{rsvpField.name}:</b> {stat.rsvpChoiceFields?.[rsvpField.id].count}
                </>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export function useRsvpStats() {
  const party = useEditableParty();

  const { data: stat, load } = useFetch<IResult | null>(
    `/api/party/${party.id}/rsvp/stats`,
    null,
    {}
  );

  return {
    render: () => <RsvpStats stat={stat} party={party} />,
    load,
  };
}
