import { Button } from '@mui/material';
import { isAfter, parseISO, startOfDay } from 'date-fns';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { BaseBlock, IBaseBlockWrapperProps } from '../BaseBlock';

import s from './index.module.scss';

import { Flex } from '#root/Components/Flex';
import { useFeature } from '#root/hooks/use-feature';
import { useTranslation } from '#root/hooks/use-translation';
import { useStoreState } from '#root/store';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';

export const Actions: FC<IBaseBlockWrapperProps> = ({ className }) => {
  const photosFeatureEnabled = useFeature('PHOTOS');
  const t = useTranslation();
  const { photoBookEnabled, photosEnabled } = useStoreState(
    state => state.party.party ?? ({} as any)
  );
  const photosUrl = useStoreState(state => {
    const { sid, slug, photosEnabledDate, date } = state.party.party ?? {};

    if (
      date &&
      (photosEnabled || photoBookEnabled) &&
      isAfter(new Date(), startOfDay(parseISO(photosEnabledDate ?? date)))
    ) {
      return `/${slug ?? sid}/photos`;
    }
  });
  const actions = useStoreState(state => state.party.party?.actions ?? {});

  const allActions = {
    ...actions,
  };

  if (photosFeatureEnabled && photosUrl) {
    allActions[photosUrl] = photosEnabled
      ? t(Keys.party.photos.action)
      : t(Keys.party.photobook.action);
  }

  return (
    <BaseBlock className={className}>
      {({ editable }) =>
        editable ? (
          <></>
        ) : Object.keys(allActions).length ? (
          <Flex gap column className={css.spaceBottomL}>
            {Object.keys(allActions).map(url => (
              <Button
                className={s.action}
                variant="contained"
                disableElevation
                color="primary"
                key={url}
                to={url}
                size="large"
                component={Link}
              >
                {allActions[url]}
              </Button>
            ))}
          </Flex>
        ) : null
      }
    </BaseBlock>
  );
};
