import type { PartyTypes } from '#root/Components/PartyWizard/Types';
import type { IParameters } from './parameters';

export interface IPaymentMessageType {
  source: string;
  payment?: IParameters['payment'];
}

export enum Language {
  DA = 'da',
  EN = 'en',
}

export type Currency = 'dkk' | 'eur';

export interface IUser {
  id: string;
  email: string;
  email_verified: boolean;
  phone?: string;
  phone_verified: boolean;
  name: string;
  language: string;
  initials: string;
  marketingConsent: boolean;
  muteRsvps: boolean;
  muteRsvpMessages: boolean;
}

export interface ILocation {
  address: string;
  latitude: number;
  longitude: number;
}

export interface IRsvpField {
  id: string;
  name: string;
  type: 'text' | 'choice';
  required: boolean;
  hidden: boolean;
}

export type Plan = 'free' | 'premium' | 'pro';
export type Feature =
  | 'rsvp'
  | 'wishlist'
  | 'photos'
  | 'cohosts'
  | 'custom_url'
  | 'rsvp_fields'
  | 'sms_sender'
  | 'wishlist_reserve'
  | 'white_label'
  | 'visibility';

export type LimitType = 'guests' | 'photos' | 'photos_size';

export interface IParty {
  id: string;
  sid: string;
  type: PartyTypes;
  createdAt: string;
  modifiedAt: string;
  language: Language;
  date: string;
  location?: ILocation;
  suDate?: string;
  slug?: string;
  rsvpEnabled: boolean;
  rsvpRestricted: boolean;
  rsvpDeadlineEnabled: boolean;
  rsvpReplyEmailDisabled: boolean;
  guestCountChoiceEnabled: boolean;
  rsvpFields: IRsvpField[];
  title: string;
  heroImage?: string;
  description: string;
  body: string;
  actions: { [url: string]: string };
  photosEnabled: boolean;
  photoBookEnabled: boolean;
  photosEnabledDate?: string;
  photosPublicEnabled: boolean;
  wishlistReservationsEnabled: boolean;
  features: Feature[];
  limits: { [key in LimitType]: number };
  themeName: string;
}

export type MessageToWhomType =
  | 'all'
  | 'missing'
  | 'custom'
  | 'rsvp'
  | 'rsvpYes'
  | 'rsvpNo'
  | 'withoutRsvp';

export interface IMessageTemplate {
  id: string;
  createdAt: string;
  modifiedAt: string;
  name: string;
  type: 'invitation' | 'message';
  email: string;
  text: string;
}

export const isEditableParty = (party: IEditableParty | IParty): party is IEditableParty =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeof (party as any).owner !== 'undefined';

interface IPrices {
  premium: {
    dkk: number;
    eur: number;
  };
  pro: {
    dkk: number;
    eur: number;
  };
  token_1: {
    dkk: number;
    eur: number;
  };
  unlimited_photos: {
    dkk: number;
    eur: number;
  };
}
export interface IEditableParty extends IParty {
  owner: IUser['id'];
  isOwner: boolean;
  isCohost: boolean;
  cohosts: IUser['id'][];
  cohostInvites: Array<{
    id: string;
    email: string;
    token: string;
    user?: {
      id: IUser['id'];
      name: IUser['name'];
    };
    createdAt: string;
  }>;
  smsUseSenderNumber: boolean;
  messageTemplates: IMessageTemplate[];
  tokens: number;
  stat: {
    emails: number;
    sms: number;
  };
  photosPublicDefaultEnabled: boolean;
  visibility: 'open' | 'pasword' | 'restricted';
  plan: Plan;
  coupon?: string;
  prices: IPrices;
  originalPrices?: IPrices;
  photosUploadedByGuests: number;
}
export interface IPartySettings
  extends Pick<
    IEditableParty,
    | 'slug'
    | 'language'
    | 'rsvpEnabled'
    | 'rsvpRestricted'
    | 'rsvpDeadlineEnabled'
    | 'rsvpFields'
    | 'guestCountChoiceEnabled'
    | 'smsUseSenderNumber'
    | 'photosEnabled'
    | 'photosPublicEnabled'
    | 'photosPublicDefaultEnabled'
    | 'wishlistReservationsEnabled'
    | 'visibility'
  > {
  photosEnabledDate: IParty['photosEnabledDate'] | null;
}

export interface MessageHistory {
  template: string;
  statusMail?: string;
  statusText?: string;
  date: string;
}

export interface IContact {
  id: string;
  sid: string;
  rsvp?: Pick<IRsvp, 'id' | 'isComming' | 'numberOfPeople' | 'name'>;
  createdAt: string;
  hasReceivedInvite?: boolean;
  firstVisit: string;
  name: string;
  email: string;
  phone: string;
  hasEmail: boolean;
  receiveEmail: boolean;
  receiveSms: boolean;
  maxPeople: number | undefined;
  messages: MessageHistory[];
}

export interface IPublicContact extends Pick<IContact, 'id' | 'sid' | 'name' | 'maxPeople'> {
  hasEmail: boolean;
  hasPhone: boolean;
}

export interface IThreadMessage {
  id: string;
  isHost: boolean;
  createdAt: string;
  message: string;
}
export interface IRsvp {
  id: string;
  contact: Pick<IContact, 'id' | 'name' | 'maxPeople' | 'hasEmail' | 'email' | 'phone'> | null;
  hasEmail: boolean;
  createdAt: string;
  isComming: boolean;
  numberOfPeople: number;
  name: string;
  comment: string;
  fields: {
    [id: string]: string | boolean;
  };
  thread: IThreadMessage[];
}

export interface IRsvpFormData extends Omit<IRsvp, 'contact'> {
  contact: { id: IContact['id']; name: IContact['name']; maxPeople: IContact['maxPeople'] } | null;
}

export type IPublicRsvp = Pick<
  IRsvp,
  'id' | 'name' | 'createdAt' | 'isComming' | 'numberOfPeople' | 'comment' | 'fields' | 'thread'
>;
export interface IRsvpPublicFormData
  extends Pick<IPublicRsvp, 'id' | 'name' | 'isComming' | 'numberOfPeople' | 'comment' | 'fields'> {
  email?: string;
}

export type ISettingsCheckedSlugResult = boolean;

export interface IWish {
  id: string;
  title: string;
  image?: string;
  link?: string;
  sort: string;
  createdAt: string;
  reservedAt?: string;
  reservedBy?: {
    id: IUser['id'];
  };
}

export interface IEditableWish extends Omit<IWish, 'image'> {
  upload?: Blob | null;
  image?: string | null;
}

export interface IPhoto {
  id: string;
  src: string;
  createdAt: string;
}

export interface IEditablePhoto extends IPhoto {
  name: string;
  public: boolean;
  contactId?: IContact['id'];
  userId?: IContact['id'];
}

export interface IHelpEntry {
  userId: IUser['id'];
  userEmail: string;
  partyId: IParty['id'] | 'other';
  entry: string;
}
