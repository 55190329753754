import { AddPhotoAlternate } from '@mui/icons-material';
import { Button, IconButton, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { FC, FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import s from './Upload.module.scss';

import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IEditablePhoto, IParty } from '#root/store/types';
import { Keys } from '#root/translations-keys';

export interface IProps {
  partyId: IParty['id'];
  onUploadDone?: (photos: IEditablePhoto[]) => void;
  onUploadEach: (photo: IEditablePhoto) => void;
  forceOpen?: boolean;
}

export const Upload: FC<IProps> = ({ partyId, onUploadDone, onUploadEach, forceOpen = false }) => {
  const t = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadTotal, setUploadTotal] = useState(0);
  const [uploadRemaining, setUploadRemaining] = useState(0);
  const nameInputRef = useRef<HTMLInputElement>();
  const [name, setName] = useState<string>();
  const currentUser = useStoreState(state => state.app.currentUser);
  const currentlyLookingContact = useStoreState(state => state.party.currentlyViewingContact);
  const needsToProvideName = !currentUser && !currentlyLookingContact && !name;

  const [isOpen, setIsOpen] = useState(forceOpen);
  useEffect(() => setIsOpen(forceOpen), [forceOpen]);
  useEffect(() => {
    // When dismounting cancel all uploads.
    return () => getService('photos').cancelUploads();
  }, []);
  const onDrop = useCallback(
    async (files: File[]) => {
      if (!needsToProvideName && files.length) {
        setIsOpen(false);
        try {
          const uploadedPhotos = await getService('photos').upload(
            partyId,
            files,
            name,
            currentlyLookingContact,
            (total, done, failed, photo) => {
              setIsUploading(true);
              setUploadTotal(total);
              setUploadRemaining(total - done - failed);
              if (photo) {
                onUploadEach(photo);
              }
            }
          );
          setUploadRemaining(0);
          onUploadDone?.(uploadedPhotos);
        } catch (e) {
          console.error(e);
        }
        setIsUploading(false);
      }
    },
    [partyId, name, needsToProvideName, onUploadDone, onUploadEach, currentlyLookingContact]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    multiple: true,
    onDrop,
    noClick: true,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(e => {
    e.preventDefault();
    setName(nameInputRef.current?.value);
  }, []);

  return (
    <div className={s.container}>
      <IconButton
        onClick={() => setIsOpen(true)}
        title={t(keys => keys.party.wishlist.upload_image)}
        disabled={isUploading}
      >
        {isUploading ? (
          <Flex gap align="center">
            <Loading />
            {uploadTotal - uploadRemaining} / {uploadTotal}
          </Flex>
        ) : (
          <AddPhotoAlternate color="primary" />
        )}
      </IconButton>
      <div
        {...getRootProps()}
        className={clsx(s.dropzone, isOpen && s.open, isDragActive && s.dragging)}
      >
        <input {...getInputProps()} />
        {isOpen ? (
          <>
            {needsToProvideName ? (
              <Flex column align="center" className={s.nameInput}>
                <form onSubmit={onSubmit}>
                  <TextField
                    inputRef={nameInputRef}
                    aria-label={t(Keys.generic.name)}
                    placeholder={t(Keys.generic.name)}
                  />
                  <Button disableElevation variant="contained" type="submit">
                    {t(Keys.generic.ok)}
                  </Button>
                </form>
                <p>{t(Keys.party.photos.upload_description)}</p>
              </Flex>
            ) : (
              <>
                <Button disableElevation variant="contained" onClick={open}>
                  {t(Keys.party.photos.select_file)}
                </Button>
                <div className={s.target}>{t(Keys.party.photos.drag_to_here)}</div>
              </>
            )}
            {!forceOpen ? (
              <Button
                disableElevation
                variant="contained"
                color="secondary"
                onClick={() => setIsOpen(false)}
                size="small"
              >
                {t(Keys.generic.close)}
              </Button>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};
