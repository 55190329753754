import { CloseFullscreen, OpenInFull, Public, PublicOff } from '@mui/icons-material';
import {
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Tooltip,
} from '@mui/material';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import React, { FC, useCallback, useState } from 'react';
import Lightbox from 'yet-another-react-lightbox';
import Download from 'yet-another-react-lightbox/plugins/download';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

import { Upload } from './Upload';
import s from './index.module.scss';

import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Flex } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { PagerActions } from '#root/Components/Pager/PagerActions';
import { useConfirm } from '#root/hooks/use-confirm';
import { useNavigate } from '#root/hooks/use-navigate';
import { usePager } from '#root/hooks/use-pager';
import { usePartyTitle } from '#root/hooks/use-party-title';
import { useRedirectIfNotSupports } from '#root/hooks/use-redirect-if-logged-in';
import { useSlider } from '#root/hooks/use-slider';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IEditablePhoto, IParty, IPhoto } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon, ThreeDotsIcon } from '#root/utils/icons';

import 'yet-another-react-lightbox/styles.css';

const Photos: FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isConfirmed } = useConfirm();

  const expand = useCallback(() => setIsExpanded(prev => !prev), []);
  const rowsPerPage = isExpanded ? 18 : 9;
  const party = useStoreState(state => state.party.party as IParty);
  const canEdit = useStoreState(state => state.party.canEdit);
  const t = useTranslation();
  const navigate = useNavigate();
  usePartyTitle(t(Keys.party.photos.title));
  useRedirectIfNotSupports('photos');
  const onClose = useCallback(() => navigate(`/${party.slug || party.sid}`, true), [party]);

  const pager = usePager<IEditablePhoto>({
    url: `/api/party/${party.id}/photos`,
    defaultSort: ['createdAt', 'desc'],
    rowsPerPage,
    enabled: party.photosPublicEnabled || canEdit,
  });
  const { setData, refresh } = pager;
  const onUploadEach = useCallback(
    (uploadedPhoto: IEditablePhoto) => {
      setData(list => [uploadedPhoto, ...(list ?? [])]);
    },
    [setData]
  );

  const forceShowUpload = pager.data?.length === 0 || (!party.photosPublicEnabled && !canEdit);

  const slider = useSlider(pager.data ?? []);

  const onDelete = useCallback(
    async (photo: IPhoto) => {
      if (await isConfirmed(Keys.party.photos.delete.confirm, Keys.generic.delete, 'error')) {
        getService('photos')
          .delete(party.id, photo.id)
          .then(() => refresh());
      }
    },
    [refresh]
  );

  const togglePublic = useCallback(
    (photo: IPhoto, setToPublic: boolean) => {
      // Optimistic update to data + api
      pager.setData(data =>
        data?.map(prevPhoto =>
          prevPhoto.id === photo.id ? { ...prevPhoto, public: !prevPhoto.public } : prevPhoto
        )
      );
      getService('photos').changePublic(party.id, photo.id, setToPublic);
    },
    [party]
  );

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={isExpanded ? 'xl' : 'sm'}
        open={true}
        onClose={onClose}
        disableEscapeKeyDown
        PaperProps={{
          sx: {
            minHeight: isExpanded ? '70vh' : undefined,
          },
        }}
      >
        <DialogTitle style={{ marginRight: 40 }}>
          <Flex gap align="center">
            {t(Keys.party.photos.title)}
            {party.photosEnabled || canEdit ? (
              <Upload partyId={party.id} onUploadEach={onUploadEach} forceOpen={forceShowUpload} />
            ) : null}
            <div className={s.dialogButtons}>
              {party.photosPublicEnabled || canEdit ? (
                <div className={css.hideOnMobile}>
                  <IconButton onClick={expand}>
                    {isExpanded ? (
                      <CloseFullscreen fontSize="small" />
                    ) : (
                      <OpenInFull fontSize="small" />
                    )}
                  </IconButton>
                </div>
              ) : null}
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </Flex>
        </DialogTitle>
        <DialogContent className={s.dialogContent}>
          {pager.data && pager.data.length > 0 ? (
            <div className={clsx(s.grid, isExpanded && s.expandedGrid)}>
              {pager.data.slice(0, rowsPerPage).map((photo, index) => (
                <div className={s.photoContainer} key={photo.id}>
                  <ButtonBase onClick={() => slider.open(index)}>
                    {/* `photo.src` starts with a `/` */}
                    <img src={`/image${photo.src}?w=500`} />
                  </ButtonBase>
                  {canEdit ? (
                    <div className={s.editMenu}>
                      <Tooltip
                        title={
                          photo.public
                            ? t(Keys.party.photos.make_non_public)
                            : t(Keys.party.photos.make_public)
                        }
                      >
                        <IconButton size="small" onClick={() => togglePublic(photo, !photo.public)}>
                          {photo.public ? (
                            <Public fontSize="small" className={s.publicIcon} />
                          ) : (
                            <PublicOff fontSize="small" />
                          )}
                        </IconButton>
                      </Tooltip>
                      <div>{photo.name}</div>
                      <DropdownMenu
                        icon={<ThreeDotsIcon fontSize="small" />}
                        IconButtonProps={{ size: 'small' }}
                      >
                        {({ closeDropdown }) => (
                          <List>
                            <ListItem className={s.meta}>
                              {t(keys => keys.party.photos.meta, {
                                name: photo.name,
                                date: format(parseISO(photo.createdAt), 'dd/MM/yyyy - HH:mm'),
                              })}
                            </ListItem>
                            <Divider />
                            <MenuItem onClick={() => closeDropdown(onDelete(photo))}>
                              {t(keys => keys.generic.delete)}
                            </MenuItem>
                          </List>
                        )}
                      </DropdownMenu>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : (
            <div className={s.loadingContainer}>
              {!forceShowUpload ? (
                pager.data === undefined ? (
                  <Loading />
                ) : (
                  <p>{t(Keys.party.photos.no_photos)}</p>
                )
              ) : null}
            </div>
          )}
        </DialogContent>
        <DialogActions className={s.actions}>
          <PagerActions pager={pager} canRefresh order={['loading', 'prev', 'next', 'refresh']} />
        </DialogActions>
      </Dialog>
      <Lightbox
        open={slider.isOpen}
        index={slider.index}
        close={() => slider.close()}
        slides={!pager.loading ? slider.slides : []}
        render={{
          buttonNext: pager.lastPage && slider.isEnd ? () => null : undefined,
          buttonPrev: pager.firstPage && slider.isBeginning ? () => null : undefined,
        }}
        plugins={canEdit ? [Download, Zoom] : undefined}
        on={{
          view: ({ index }) => {
            if (slider.isOpen && slider.index !== index) {
              if (!pager.lastPage && slider.isEnd && index === 0) {
                // Moving Forward, should load next page
                pager.next();
              } else if (!pager.firstPage && slider.isBeginning && index !== 1) {
                // assuming we cannot advance one slide, then we must be moving from index 0 to end of list.
                pager.previous();
              }
              slider.setIndex(index);
            }
          },
        }}
      />
    </>
  );
};

export default Photos;
